/* src/components/LoginPasswordForm.css */
.login-id-form-container {
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-heading {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .inline-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap; /* Allows wrapping if there isn't enough space */
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .form-group label {
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button, a {
    padding: 8px 12px;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 8px; /* Adjust if needed to align vertically */
  }
  
  button:hover, a:hover {
    cursor: pointer;
  }
  