
/* src/App.css */

body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://files.desmaximus.com/back-davivienda.png');
  background-size: cover;
  background-position: center;
  filter: blur(10px); /* Blurring the background image */
  z-index: -1; /* Place behind the content */
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ed1c27;
  backdrop-filter: blur(5px); /* Blurred background for the header */
  z-index: 1;
}

.logo {
  height: 50px; /* Adjust as needed */
}

.app-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  z-index: 1;
}

.form-background {
  backdrop-filter: blur(10px); /* Blurred background */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blurred background for the footer */
  z-index: 1;
}

.footer-link {
  margin: 0 10px;
  color: #007bff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.form-heading {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.form-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .form-content {
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 10px;
    width: 100%;
  }
}
